.custom-recently-viewed {
    padding: 0 15px;

    .block-title {
        text-align: center;
        text-transform: uppercase;
        margin-top: 50px;

        > strong {
            font-weight: normal;
        }
    }

    .product-items {
        .column.main & {
            margin-left: 0;
        }
    }
}

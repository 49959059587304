.cms-noroute-index {
    .columns .column.main {
        //
        // Desktop
        //
        @include min-screen($screen__m) {
            padding-top: 30px;
        }
    }
}

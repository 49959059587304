.social-icons,
.scope-social-icons {
    clear: both;
    padding-top: 20px;
    font-size: 0;
    color: transparent;

    &__list,
    > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center;

        .nav-sections & {
            text-align: left;
            padding-left: 10px;
        }
    }

    &__item,
    > ul > li {
        display: inline-block;
        margin: 0 5px;
        height: 35px;
        width: 35px;
        background-size: contain;

        > a {
            display: block;
            width: 100%;
            height: 100%;
        }

        .nav-sections & {
            margin-right: 10px;
            width: 24px;
            height: 24px;

            img {
                height: 24px;
                width: 24px;
            }
        }
    }

    .facebook {
        background-image: url('../images/icons/facebook-white.svg');
    }

    .instagram {
        background-image: url('../images/icons/instagram-white.svg');
    }

    .twitter {
        background-image: url('../images/icons/twitter-white.svg');
    }

    .nav-sections & {
        .facebook {
            background-image: url('../images/icons/facebook-dark.svg');
        }

        .instagram {
            background-image: url('../images/icons/instagram-dark.svg');
        }

        .twitter {
            background-image: url('../images/icons/twitter-dark.svg');
        }
    }

    //
    // Desktop
    //
    @include min-screen($screen__m) {
        padding-top: 10px;

        &__list,
        > ul {
            text-align: right;
        }

        &__item,
        > ul > li {
            margin: 0 0 0 10px;
        }
    }
}

.category-link {
    background-position: 50% 50%;
    background-size: cover;
    background-color: $color-gray-base;

    &:hover,
    &:active {
        text-decoration: none;
        color: $color-white;
    }

    &__img {
        position: absolute;
        z-index: 1;
        left: 0;
        top:0;
        width: 100%;
        height: 100%;

        img,
        picture {
            display:block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        position: relative;
        display: block;
        padding: 55px 20px;
        text-align: center;
        color: $color-white;
        z-index: 2;
    }

    &__title {
        @include lib-font-size(35);
        font-weight: normal;
        border: 2px solid $color-white;
        padding: 2px 4px;
    }

    @include max-screen(970px) {
        &__title {
            @include lib-font-size(25);
        }
    }

    @include max-screen(500px) {
        &__title {
            @include lib-font-size(20);
        }
    }

    @include max-screen(350px) {
        &__title {
            @include lib-font-size(15);
        }
    }
}

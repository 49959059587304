$banner-bg-color: $color-black;
$corner-max-width: 400px;

$full-content-size: 85%;
$full-button-size: 15%;

$side-spaces: 30px;
$bottom-spaced: 50px;

//
//  Common (Both desktop and mobile)
//  _____________________________________________

#magestat-cookie-law-banner {
    background: $banner-bg-color;
    color: $color-white;
    display: none;
    overflow: hidden;
    padding: 20px;
    position: fixed;
    z-index: 9999999991 !important; // Forcing index to make sure it will be above all (including live chat).

    &.display {
        display: block;
    }

    &.bottom-left,
    &.bottom-right,
    &.top-left,
    &.top-right {
        max-width: $corner-max-width;
        text-align: center;
    }

    &.bottom-left,
    &.top-left {
        left: $side-spaces;
    }

    &.bottom-right,
    &.top-right {
        right: $side-spaces;
    }

    &.bottom-full {
        bottom: 0;
    }

    &.top-full {
        top: 0;
    }

    &.bottom-left,
    &.bottom-right {
        bottom: $bottom-spaced;
    }

    &.top-left,
    &.top-right {
        top: $bottom-spaced;
    }

    &.bottom-full,
    &.top-full {
        left: 0;
        padding: 10px 20px;
        right: 0;
        width: 100%;

        .content-inner-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            max-width: 960px;
            margin: 0 auto;

            .banner-content-data {
                width: $full-content-size;
            }
        }

        .banner-button {
            align-items: center;
            display: flex;
            justify-content: center;
            margin: 0;
            width: $full-button-size;
        }
    }

    &.default-style {
        padding: 12px 20px 12px 25px;
        font-size: 1.3rem;
        background: #ffee9c;
        border-color: #d6ca8e;
        color: #333;
    }

    .banner-title {
        h3 {
            margin: 20px 0;
            text-transform: uppercase;
        }
    }

    .banner-content {
        p {
            margin: 5px 0;
        }

        a {
            color: $color-white;
        }

        .link {
            text-decoration: underline;
        }
    }
}


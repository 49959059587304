//
//  Shopping cart
//  ---------------------------------------------
.checkout-cart-index {
    .page-title {
        @include lib-font-size(30);
    }
}

//  Summary block
.cart-summary {
    @extend .abs-add-box-sizing;
    @extend .abs-adjustment-incl-excl-tax;
    margin-bottom: $indent__m;
    padding: 1px 0 $indent__m;

    > .title {
        display: block;
        @include lib-heading(h3);
    }

    .block {
        margin-bottom: 0;

        .item-options {
            margin-left: 0;
        }

        .fieldset {
            margin: 15px 0 $indent__m $indent__xs;

            .field {
                margin: 0 0 $indent__s;

                &.note {
                    font-size: $font-size__s;
                }

                &.item {
                    margin-right: 20px;
                }
            }

            .methods {
                .field {
                    > .label {
                        display: inline;
                    }
                }
            }
        }

        .fieldset.estimate {
            > .legend,
            > .legend + br {
                @extend .abs-no-display;
            }
        }

        &.discount,
        &.amcard-giftcards {
            @include lib-clearfix();
            background: $color-gray-base;
            color: $color-white;
            margin-bottom: 10px;

            > .title {
                padding: 10px 15px 0;

                strong {
                    .column.main & { // Specificity override hack
                        @include lib-font-size(16);
                        font-weight: normal;
                        text-transform: uppercase;
                    }
                }
            }

            > .content {
                display: block !important; // Override collapsible on cart
                padding: 0 15px 15px;
            }

            .fieldset {
                margin: 0;
                display: flex;

                .field {
                    flex: 1;

                    .label {
                        display: none;
                    }

                    input[type="text"] {
                        height: 40px;
                    }
                }
            }

            .actions-toolbar {
                margin-left: 10px;

                .action {
                    padding: 10px 12px;
                }
            }

            .amcard-title {
                display: none;
            }

            .amcard-field-block {
                display: flex;
            }

            .amcard-field {
                height: 40px;
            }

            .amcard-status-link {
                color: $color-white;
                float: left;
                margin: 5px 0 15px;
            }
        }

        &.shipping {
            background: $color-gray-01;
            margin-bottom: 10px;

            .title {
                padding: 10px 15px 10px;
                margin: 0;

                strong {
                    .column.main & { // Specificity override hack
                        @include lib-font-size(16);
                        font-weight: normal;
                        text-transform: uppercase;
                    }
                }
            }

            .title:after {
                background-image: url('../images/icons/arrow-expand-dark.svg');
            }

            &.active .title:after {
                background-image: url('../images/icons/arrow-collapse-dark.svg');
            }

            .fieldset {
                margin: 0;
                padding: 10px 15px 0;

                &.rate {
                    padding-bottom: 15px;
                }
            }

            .field {
                float: left;

                select,
                input {
                    height: 30px;
                    padding-left: 3px;
                    padding-right: 3px;
                }
            }

            .field[name="shippingAddress.country_id"],
            .field[name="shippingAddress.region"],
            .field[name="shippingAddress.region_id"] {
                width: 36%;
                margin-right: 1%;
            }

            .field[name="shippingAddress.postcode"] {
                width: 26%;

                .message.warning {
                    margin-left: -283%;
                }
            }

            .field.note,
            .item-title {
                display: none;
            }

            .field.note {
                &--no-methods {
                    display: block;
                    float: none;
                    padding: 0 15px 15px;
                }
            }
        }
    }
}

//  Totals block
.cart-totals {
    background: $color-gray-01;
    padding: 10px 5px 15px;

    .mark {
        font-weight: normal;
        padding-bottom: 0;
    }

    .amount {
        text-align: right;
        padding-bottom: 0;
    }

    .table-wrapper {
        margin-bottom: 0;
        overflow: inherit;
    }

    .grand.totals {
        @include lib-font-size(18);
    }
}

//  Products table
.cart.table-wrapper {
    .items {
        thead + .item {
            border-top: $border-width__base solid $border-color__base;
        }

        > .item {
            border-bottom: $border-width__base solid $border-color__base;
            position: relative;
        }
    }

    .col {
        &.qty {
            .input-text {
                @extend .abs-input-qty;
                margin-top: -5px;
                height: 35px;
            }

            .label {
                @extend .abs-visually-hidden;
            }
        }
    }

    .item {
        &-actions td {
            padding-bottom: $indent__s;
            text-align: center;
            white-space: normal;
        }

        .col {
            &.item {
                display: block;
                min-height: 75px;
                padding: $indent__m 0 $indent__s 75px;
                position: relative;
            }
        }
    }

    .actions-toolbar {
        @extend .abs-add-clearfix;

        > .action {
            @extend button;
            @include lib-link-as-button();
            margin-bottom: $indent__s;
            margin-right: $indent__s;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .action {
        &.help.map {
            @extend .abs-action-button-as-link;
            font-weight: $font-weight__regular;
        }

        &.action-delete,
        &.action-delete:hover {
            padding: 0;
            border: none;
            background: url('../images/icons/bin-red.svg') 50% 50% no-repeat transparent;
            height: 30px;
            width: 30px;
            margin-top: -8px;
            background-size: 22px;

            > span {
                display: none;
            }
        }
    }

    .product {
        &-item-photo {
            display: block;
            left: 0;
            max-width: 60px;
            padding: 0;
            position: absolute;
            top: 15px;
            width: 100%;
        }

        &-item-details {
            white-space: normal;
        }

        &-item-name {
            display: inline-block;
            font-weight: $font-weight__regular;
            margin-top: -6px;
        }
    }

    .gift-registry-name-label {
        &:after {
            content: ':';
        }
    }

    //  Product options
    .item-options {
        @extend .abs-product-options-list;
        @extend .abs-add-clearfix;
        font-size: $font-size__s;
        margin-bottom: $indent__s;
    }

    .product-item-name + .item-options {
        margin-top: $indent__s;
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .action.configure {
        display: inline-block;
        margin: 0 0 $indent__base;
    }
}

.cart-container {
    .form-cart {
        @extend .abs-shopping-cart-items;
    }

    .checkout-methods-items {
        @extend .abs-reset-list;
        background: $color-gray-01;
        text-align: center;
        padding: 0 15px 15px;

        .action.primary {
            @extend .abs-button-l;
            @include lib-font-size(20);
            padding: 24px 17px;
            width: 100%;
        }

        .item {
            & + .item {
                margin-top: 15px;
            }

            .paypal {
                &:before {
                    content: attr(data-label);
                    text-transform: uppercase;
                    margin-bottom: 15px;
                    display: block;
                }
            }
        }
    }

    .products-carousel {
        padding: 0;
        clear: none;
    }

    //
    //  Desktop
    //
    @include min-screen($screen__m) {
        .products-carousel {
            width: 65.6%;
        }
    }
}

//  Products pager
.cart-products-toolbar {
    .toolbar-amount {
        margin: $indent__m 0 15px;
        padding: 0;
        text-align: center;
    }

    .pages {
        margin: 0 0 $indent__m;
        text-align: center;

        .items {
            > .item {
                border-bottom: 0;
            }
        }
    }
}

.cart-products-toolbar-top {
    border-bottom: $border-width__base solid $border-color__base;
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
    margin-top: 70px;
}

.cart-tax-info,
.cart .cart-tax-info {
    + .cart-tax-total {
        display: block;
    }
}

.cart.table-wrapper {
    border: 1px solid $color-gray-base;
    margin-bottom: 10px;

    thead .col {
        font-weight: normal;
        text-transform: uppercase;
        background: $color-gray-base;
        color: $color-white;
    }
}

.cart.table-wrapper,
.order-items.table-wrapper {
    .col.price,
    .col.qty,
    .col.msrp {
        text-align: right;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .cart {
        &.table-wrapper {
            overflow: inherit;

            thead {
                display: none;
            }

            .col {
                &.qty,
                &.price,
                &.subtotal,
                &.msrp {
                    @include lib-font-size(12);
                    box-sizing: border-box;
                    display: block;
                    text-align: left;
                    white-space: nowrap;
                    padding-top: 0;
                    padding-left: 75px;

                    &:before {
                        content: attr(data-th) ':';
                        display: inline;
                        font-weight: $font-weight__bold;
                        padding-bottom: $indent__s;
                    }
                }

                &.qty {
                    .field.qty {
                        display: inline-block;
                        margin-left: 5px;
                        margin-top: 4px;
                        margin-bottom: -7px;

                        .input-text {
                            height: 30px;
                        }
                    }
                }

                &.msrp {
                    white-space: normal;
                }

                &.actions {
                    width: 5%;
                }
            }

            .item {
                .col.item {
                    padding-bottom: 0;
                }
            }

            .price-including-tax,
            .price-excluding-tax {
                display: inline;

                .price {
                    @include lib-font-size(12);
                    font-weight: normal;
                }
            }

            .actions-toolbar > .action {
                margin-top: 20px;
            }
        }
    }

    .cart-container {
        .form-cart {
            @extend .abs-shopping-cart-items-mobile;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .cart-container {
        @extend .abs-add-clearfix-desktop;
        .form-cart {
            @extend .abs-shopping-cart-items-desktop;
        }

        .widget {
            float: left;
        }
    }

    .cart-summary {
        float: right;
        position: relative;
        width: 33.5%;

        .actions-toolbar {
            .column.main & {
                @extend .abs-reset-left-margin-desktop;
                > .secondary {
                    float: none;
                }
            }
        }

        .block {
            .fieldset {
                .field {
                    @include lib-form-field-type-revert($_type: block);
                    margin: 0 0 $indent__s;
                }
            }
        }
    }

    .cart {
        &.table-wrapper {
            .items { // Google Chrome version 44.0.2403.107 m fix
                min-width: 100%;
                width: auto;
            }

            .item {
                .col {
                    padding-top: 25px;

                    &.item {
                        padding: 25px 8px $indent__s;
                    }
                }

                &-actions {
                    td {
                        text-align: right;
                    }
                }
            }

            .product {
                &-item-photo {
                    display: table-cell;
                    max-width: 100%;
                    padding-right: $indent__base;
                    position: static;
                    vertical-align: top;
                    width: 1%;
                }

                &-item-details {
                    text-align: left;
                    display: table-cell;
                    vertical-align: top;
                    white-space: normal;
                    width: 99%;
                }
            }

            .price-including-tax,
            .price-excluding-tax {
                @include lib-font-size(15);
            }

            .item-actions {
                .actions-toolbar {
                    @extend .abs-reset-left-margin-desktop;
                    text-align: left;
                }
            }
        }

        .action {
            margin-bottom: $indent__s;
        }
    }

    //  Products pager
    .cart-products-toolbar {
        .toolbar-amount {
            line-height: 30px;
            margin: 0;
        }

        .pages {
            float: right;
            margin: 0 0 1px;

            .item {
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .cart.table-wrapper {
        .cart-products-toolbar {
            + .cart {
                thead {
                    tr {
                        th.col {
                            padding-bottom: 7px;
                            padding-top: 8px;
                        }
                    }
                }
            }
        }

        .cart {
            + .cart-products-toolbar {
                margin-top: $indent__m;
            }
        }
    }
}

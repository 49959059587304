//  Newsletter subscription
.block.newsletter {
    padding: 15px 0;
    background: $footer__newsletter-background;
    margin-bottom: 40px;

    .footer.content {
        padding-bottom: 0;
    }

    .text {
        text-align: center;
        color: $color-white;
        margin: 0 0 10px;
    }

    .form.subscribe {
        display: table;
        width: 100%;
    }

    .fieldset {
        display: table-cell;
        margin: 0;
        padding: 0;
        vertical-align: top;
    }

    .field {
        margin: 0;

        .control {
            display: block;

            &:before {
                position: absolute;
            }
        }

        input {
            height: 45px;
        }
    }

    .title {
        display: none;
    }

    .label {
        @extend .abs-visually-hidden;
    }

    .actions {
        display: table-cell;
        vertical-align: top;
        width: 1%;
    }

    .action.subscribe {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        margin-left: -1px;
        color: $footer__newsletter-button-color;
        background-color: $footer__newsletter-button-background;
        white-space: nowrap;
        padding: 14px 20px 15px;
    }

    div.mage-error {
        color: $text__color;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block.newsletter {
        .footer.content {
            > .content {
                max-width: 865px;
                margin: 0 auto;

                > .text {
                    float: left;
                    text-align: right;
                    width: 330px;
                    margin: 6px 0 0;
                }

                > .form {
                    margin-left: 345px;
                    width: calc(100% - 345px);
                }
            }
        }
    }
}

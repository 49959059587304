.mgz-btn {
    @extend .action.primary;
    color: $button-primary__color;
    width: 100%;

    &:visited {
        color: $button-primary__color;
    }
}

.magezon-builder {
    .catalog-product-quickview & {
        display: none;
    }
}

.block.filter {
    margin-bottom: $indent__xl;

    .filter-title {
        margin-bottom: 10px;
        background: $color-gray-base;
        color: $color-white;
        text-transform: uppercase;

        strong {
            display: block;
            font-size: 18px;
            font-weight: $font-weight__regular;
            padding: 15px;
        }
    }

    .filter-subtitle {
        display: none;
    }

    //
    //  Mobile
    //
    @include max-screen($screen__m) {
        margin-bottom: 15px;

        .filter-title {
            position: relative;

            > strong {
                &:after {
                    content: '';
                    position: absolute;
                    height: 13px;
                    width: 13px;
                    background: url('../images/icons/arrow-expand-white.svg') 0 0 no-repeat transparent;
                    background-size: contain;
                    top: 21px;
                    right: 15px;
                }
            }
        }

        .filter-content,
        .aw-layered-nav-sidebar-filtered {
            display: none;
        }

        &.active {
            .filter-title {
                > strong {
                        &:after {
                            background-image: url('../images/icons/arrow-collapse-white.svg');
                        }
                    }
            }

            .filter-content,
            .aw-layered-nav-sidebar-filtered {
                display: block;
            }
        }
    }
}

.block-subtitle {
    display: inline-block;
    margin-bottom: $indent__s;
}

.filter-current {
    .item {
        margin-bottom: $indent__s;
    }

    .filter-label {
        display: block;
        font-weight: $font-weight__bold;

        &:after {
            content: ':';
        }
    }

    + .filter-actions {
        margin-bottom: $indent__l;
    }
}

.filter-options-content {
    .filter-count-label {
        @extend .abs-visually-hidden;
    }
}

.filter-options {
    margin: 0;

    .filter-options-title {
        @include lib-heading(h4);
        margin: 0 0 $indent__s;
        word-break: break-all;
    }

    .filter-options-content {
        margin: 0 0 $indent__m;

        .item {
            margin-bottom: 3px;
        }
    }

    .count {
        @include lib-css(color, $text__color__muted);

        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }
}

.items {
    @extend .abs-reset-list;
}

.filtered {
    .items {
        margin: 15px 0;
    }

    .item {
        margin-bottom: 6px;
        padding-left: 22px;
        position: relative;

        .label {
            font-weight: $font-weight__bold;
        }

        .action.remove {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }
    }

    & + .actions {
        margin-bottom: 35px;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .page-with-filter {
        .columns {
            .sidebar-main {
                @include lib-vendor-prefix-order(0);
            }
        }
    }
}

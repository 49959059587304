.cms-page-view {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        text-transform: none;
    }

    h1 {
        margin-top: 10px;
    }

    h2 {
        @include lib-font-size(21);
    }
}

.contact {
    &__wrapper {
        @include make-row();
    }

    &__form,
    &__faq {
        @include make-col-ready();
    }

    &__form {
        @include make-col(12);
    }

    &__faq {
        @include make-col(12);
    }

    //
    // Desktop
    //
    @include min-screen($screen__m) {
        &__form {
            @include make-col(6);
        }

        &__faq {
            @include make-col(6);
        }
    }
}

.faq-content {
    &__title-block {
        cursor: pointer;
        background: $color-gray-light;
        color: $color-white;
        padding: 10px;
        margin-bottom: 15px;
    }

    &__inner {
        padding: 0 10px 10px;
    }
}

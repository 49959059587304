.store-details {
    &__intro {
        margin-bottom: 15px;
    }

    &__chat {
        clear: both;
        background: $color-gray-01;
        padding: 15px;
        margin-bottom: 30px;

        strong {
            text-transform: uppercase;
            color: $color-dstore-red;
        }
    }

    &__title {
        @include lib-font-size(28);
    }

    &__address {
        @include lib-font-size(18);
        margin-bottom: 20px;
    }
}

.store-opening {
    &__table {
        border-spacing: 2px;
        border-collapse: separate;
    }

    &__day {
        background: $color-black;
        color: $color-white;
        font-weight: 700;
        padding: 12px 30px;
        width: 35%;
    }

    &__time {
        padding: 12px 30px;
        background: $color-gray-01;
        text-align: center;
    }
}

.store-image {
    &__image {
        display: block;
    }

    &__text {
        display: block;
        color: $color-white;
        background: $color-dstore-red;
        text-align: center;
        text-transform: uppercase;
        padding: 15px;
    }
}

.store-map {
    height: 500px;

    &__map {
        height: 100%;
    }

    &__save-widget {
        width: 300px;
        box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
        background-color: white;
        padding: 10px;
        font-family: Roboto, Arial;
        font-size: 13px;
        margin: 15px;
    }

    &__name {
        display: block;
        margin-bottom: 5px;
    }

    &__directions-link {
        float: left;
    }
}

.store-video {
    position: relative;
    padding-bottom: 54.15%; // Aspect ratio for video
    padding-top: 25px;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

button {
    text-transform: uppercase;
    transition: background-color 300ms ease-in-out;
    background: $color-gray-base;
    color: #fff;
    border: none;

    &:hover,
    &:focus {
        background: #404041;
        border: none;
        color: #fff;
    }
}

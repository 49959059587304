.product-videos {
    &__container {
        position: relative;
        padding-bottom: 56.25%;

        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    &__iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.aw-sbb-list-brand {
    .brand-items {
        margin: 0 -10px;
    }

    .brand-item {
        display: inline-block;
        margin: 0 10px;
    }

    .brand-name {
        display: none;
    }
}

.aw-sbb-brand-info {
    .brand-logo,
    .brand-description {
        margin-bottom: 15px;
    }

    .brand-logo {
        overflow: hidden;
        height: 45px;
        width: 235px;
        margin-bottom: 40px;

        > a {
            display: block;
            position: relative;
            top: -96px;
            height: 235px;
            width: 235px;
        }
    }
}

.product-details {
    @include make-row();

    &__details,
    &__specification {
        @include make-col-ready();
        @include make-col(12);
    }

    &__table {
        border-collapse: separate;
        border-spacing: 2px;

        tr {
            th {
                width: 50%;
                background: $color-gray-light;
                border: 1px solid $color-gray-light;
            }

            td {
                width: 50%;
                border: 1px solid $color-gray-light;
            }
        }
    }

    //
    // Desktop
    //
    @include min-screen($screen__m) {
        &__details,
        &__specification {
            @include make-col(6);
        }
    }
}

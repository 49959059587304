.footer-payment-icons {
    clear: both;
    padding-top: 15px;

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: center;
    }

    &__item {
        display: inline-block;
        margin: 0 3px;
    }
}

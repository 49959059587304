.catalog-product-view {
    .price-match-modal {
        .modal-inner-wrap {
            max-width: 700px;
        }

        .modal-content {
            padding-bottom: 3rem;
        }

        .fieldset {
            margin: 0 0 2rem;

            > .legend {
                @include lib-visually-hidden();
            }
        }

        .webforms-fields-name {
            padding-right: 1.5rem;
        }

        .webforms-fields-email {
            padding-left: 1.5rem;
        }

        .product-field-options {
            &__content {

                > .field {
                    margin-bottom: 15px;

                    &:last-child {
                    margin-bottom: 0;
                    }
                }
            }
        }

        .actions-toolbar {
            > .primary {
                float: none;
                display: block;
            }
        }
    }
}

.footer-links {
    padding-top: 45px;

    &__title {
        border-bottom: 2px solid $footer__title-underline;
        padding-bottom: 12px;
        padding-right: 35px;
        margin: 0 0 12px;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 10px;
            height: 18px;
            width: 16px;
            background: url('../images/icons/arrow-expand-white.svg') 0 0 no-repeat transparent;
            background-size: contain;

            .active & {
                background: url('../images/icons/arrow-collapse-white.svg') 0 0 no-repeat transparent;
            }
        }
    }

    &__content {
        display: none;

        > ul {
            padding: 0;
            list-style: none;
            margin: 0 0 30px;

            > li {
                margin: 0 0 10px;
            }
        }

        &--bottom {
            clear: both;
            text-align: center;
            display: block;

            > ul {
                margin: 0;

                > li {
                    @include lib-font-size(12);
                    display: inline-block;
                    height: 14px;
                    line-height: 1;
                    margin: 0;

                    &:not(:last-child) {
                        border-right: 1px solid $color-white;
                        padding-right: 7px;
                        margin-right: 3px;
                    }
                }
            }
        }
    }

    &__address {
        color: $color-white;
    }

    @include min-screen($screen__m) {
        &__title {
            padding-bottom: 18px;
            padding-right: 0;
            margin-top: 20px;

            &:after {
                display: none;
            }
        }

        &__column {
            float: left;
            width: 21%;
            min-width: 200px;
            box-sizing: border-box;
            padding-right: 30px;

            &--contact {
                float: right;
                text-align: right;
                padding-right: 0;
                width: 20%;
            }
        }

        &__content,
        &__address {
            // Override accordion behaviour
            display: block !important;
            height: auto !important;
        }
    }
}

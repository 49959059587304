.breadcrumbs {
    @include lib-breadcrumbs();

    //
    //  Mobile
    //
    @include max-screen($screen__m) {
        height: 0;
        overflow: hidden;
    }
}

$toolbar-mode-icon-font-size: 24px !default;
$toolbar-element-background : $panel__background-color !default;

.page-products {
    .columns {
        position: relative;
        z-index: 1;
    }
}

.toolbar {
    @extend .abs-add-clearfix;
}

.toolbar-amount {
    display: block;
    order: 1;
    flex: 1;
    line-height: $toolbar-mode-icon-font-size + 2;
    margin: 0;
    padding: 3px 10px 0 10px;
    background: $color-gray-light;
}

.toolbar-products {
    @extend .abs-add-clearfix;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $indent__xl;

    .pages {
        order: 5;
        width: 100%;
        text-align: center;

        ~ .toolbar-sorter {
            flex: initial;
        }
    }

    .limiter {
        .control {
            display: inline-block;
        }
    }
}

.sorter-options {
    width: auto;
    float: right;
    height: 32px;
}

.sorter-action {
    vertical-align: top;
    @include lib-icon-font(
        $icon-arrow-up,
        $_icon-font-size       : 28px,
        $_icon-font-line-height: 32px,
        $_icon-font-color      : $header-icons-color,
        $_icon-font-color-hover: $header-icons-color-hover,
        $_icon-font-text-hide  : true
    );
}

.sorter {
    flex: 1;
    order: 3;
    margin-left: 5px;

    .sorter-label,
    .sorter-action {
        @include lib-visually-hidden();
        display: none;
    }

    .sort-desc {
        &:before {
            content: $icon-arrow-down;
        }
    }
}

.modes {
    display: none;
}

.limiter {
    order: 4;
    margin-left: 5px;

    .label {
        display: none;
    }
}

.limiter-options {
    width: auto;
    height: 32px;
}

.limiter-label {
    font-weight: 400;
}

.limiter-text {
    display: none;
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .columns {
            padding-top: 0;
            position: relative;
            z-index: 1;
        }
    }

    .toolbar {
        .products.wrapper ~ & .pages {
            float: left;
        }
    }

    .toolbar-amount {
        float: left;
        flex: initial;
        height: 32px;
    }

    .toolbar-products {
        .pages {
            order: 2;
            flex: 1;
            width: auto;
            margin-top: 0;
        }
    }

    .sorter {
        float: right;
    }

    .modes {
        display: block;
        float: left;

        .products.wrapper ~ .toolbar & {
            display: none;
        }
    }

    .modes-label {
        @extend .abs-visually-hidden-desktop;
    }

    .modes-mode {
        color: $text__color__muted;
        border: 1px solid $border-color__base;
        border-right: 0;
        float: left;
        font-weight: $font-weight__regular;
        line-height: 1;
        padding: 7px 10px;
        text-align: center;
        @include lib-icon-font(
            $icon-grid,
            $_icon-font-size       : $toolbar-mode-icon-font-size,
            $_icon-font-text-hide  : true,
            $_icon-font-color      : $text__color__muted,
            $_icon-font-color-hover: $text__color__muted,
            $_icon-font-display    : block
        );

        &:not(.active) {
            &:hover {
                color: $text__color__muted;
                background: darken($toolbar-element-background, 7%);
            }
        }

        &:last-child {
            border-right: 1px solid $border-color__base;
        }

        &.active {
            color: $primary__color__light;
        }
    }

    .mode-list {
        @include lib-icon-font-symbol($icon-list);
    }

    .limiter {
        float: right;

        .products.wrapper ~ .toolbar & {
            display: block;
        }
    }
}

body { // Specificity bump for override
    #algolia-autocomplete-container {
        .aa-dropdown-menu {
            width: calc(100% - 30px);

            //
            //  Desktop
            //
            @include min-screen($screen__m) {
                width: calc(80% - 30px);
                max-width: 1090px;
            }
        }
    }
}

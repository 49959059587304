//
//  Product Lists
//  _____________________________________________

.products {
    margin: $indent__l 0;
}

.product {
    &-items {
        @extend .abs-reset-list;
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;

        .products-grid & {
            display: inline-block;
            width: calc(50% - 3px);
        }

        &-details {
            .products.list & {
                text-align: center;
            }
        }

        &-name {
            @extend .abs-product-link;
            display: block;
            margin: $indent__xs 0;
            word-wrap: break-word;
            hyphens: auto;
        }

        &-info {
            max-width: 100%;
            width: 152px;

            .page-products & {
                width: 284px;
            }
        }

        &-actions {
            display: none;

            .actions-secondary {
                & > .action {
                    @extend .abs-actions-addto-gridlist;
                    &:before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }

        &-description {
            margin: $indent__m 0;
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .price-box {
            margin: 0 0 10px;

            .price {
                color: $price__color;
                font-size: 16px;
                font-weight: $font-weight__bold;
                white-space: nowrap;
            }

            .price-label {
                font-size: $font-size__s;
            }

            .has-old-price {
                .normal-price .price {
                    display: block;
                    color: $color-dstore-red;
                }
            }
        }

        .special-price,
        .minimal-price {
            .price {
                color: $price__special__color;
                font-weight: $font-weight__bold;
            }

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;

            .price-label {
                display: none;
            }
        }

        .old-price {
            color: $price__old__color;

            .price {
                color: $price__old__color;
                font-size: 12px;
                font-weight: $font-weight__regular;
            }

            .price-label {
                display: none;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .action.tocompare {
            @include lib-icon-font-symbol($icon-compare-empty);
        }

        .tocart {
            white-space: nowrap;
        }
    }
}

.category-header {
    &__content {
        background-size: cover;
        background-position: 50% 50%;
    }

    &__content-wrapper {
        padding-top: 5px;
        padding-bottom: 25px;

        .page-title-wrapper {
            max-width: 640px;
        }

        .page-title-wrapper {
            border-bottom: 1px solid $color-dstore-red;
            margin-bottom: 20px;

            .page-title {
                margin-bottom: 20px;
                font-size: 30px;
            }
        }

        &--has-image {
            padding-top: 25px;
            padding-bottom: 40px;
            margin-bottom: 30px;
            color: $color-white;

            .page-title-wrapper,
            .category-description {
                max-width: 640px;
            }
        }
    }

    &__read-more {
        display: none;
    }

    //
    //  Mobile
    //
    @include max-screen($screen__m) {
        &__content {
            background: none !important;
        }

        &__content-wrapper {
            color: $text__color;
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 0;

            .page-title-wrapper {
                margin-bottom: 0;

                .page-title {
                    font-size: 24px;
                    margin-bottom: 10px;
                }
            }
        }

        &__read-more {
            display: block;
            margin-bottom: 10px;
        }

        &__text-less {
            display: none;

            .is-category-read-more-expanded & {
                display: inline;
            }
        }

        &__text-more {
            .is-category-read-more-expanded & {
                display: none;
            }
        }
    }
}

.column.main {
    .product {
        &-items {
            margin-left: -$indent__base;
        }

        &-item {
            padding-left: $indent__base;
        }
    }

}

.price-container {
    .price {
        font-size: 14px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-item {
            display: table;
            width: 100%;

            &-info {
                display: table-row;
            }

            &-photo {
                display: table-cell;
                padding: 0 $indent__l $indent__l 0;
                vertical-align: top;
                width: 1%;
            }

            &-details {
                display: table-cell;
                vertical-align: top;
            }
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }
}

@include min-screen($screen__s) {
    .product {
        &-item {
            .products-grid & {
                margin-bottom: $indent__l;
            }

            &-actions {
                display: block;

                .products-grid & {
                    margin: $indent__s 0;
                }

                .actions-primary + .actions-secondary {
                    display: table-cell;
                    padding-left: 5px;
                    white-space: nowrap;
                    width: 50%;
                    & > * {
                        white-space: normal;
                    }
                }

                .actions-primary {
                    display: table-cell;
                }
            }
        }
    }

    .products-grid .product-item {
        width: calc(33.3333% - 3px);
    }

    .page-products,
    .page-layout-1column,
    .page-layout-3columns,
    .page-products.page-layout-1column,
    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: calc(33.3333% - 3px);
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .products-grid {
            .product-item {
                width: calc(33.3333% - 3px);
            }
        }
    }

    .page-products.page-layout-1column {
        .products-grid {
            .product-item {
                width: calc(25% - 3px);
            }
        }
    }

    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: calc(50% - 3px);
            }
        }
    }
}
@include min-screen($screen__l) {
    .products-grid {
        .product-item {
            width: calc(20% - 3px);
        }
    }

    .page-layout-1column {
        .products-grid {
            .product-item {
                width: calc(16.6666% - 3px);
            }
        }
    }

    .page-layout-3columns {
        .products-grid {
            .product-item {
                width: calc(25% - 3px);
            }
        }
    }

    .page-products {
        &.page-layout-1column {
            .products-grid {
                .product-item {
                    margin-left: 0;
                    width: 20%;
                }
            }
        }

        &.page-layout-3columns {
            .products-grid {
                .product-item {
                    margin-left: 1%;
                    width: calc(32.667% - 3px);

                    &:nth-child(3n) {
                        margin-left: 1%;
                    }

                    &:nth-child(3n + 1) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.opc-wrapper {
    .form-discount {
        max-width: 500px;
        display: flex;

        .payment-option-inner {
            flex: 1;

            .input-text {
                height: 40px;
            }
        }

        &.order-comment-form {
            .payment-option-inner {
                .input-text {
                    height: 120px;
                }
            }
        }

        .actions-toolbar {
            margin-left: 10px;

            .action-apply {
                @extend .action.primary;
                height: 40px;
            }
        }

        .field {
            .label {
                @extend .abs-visually-hidden;
            }
        }
    }
}

// Custom colors
$color-dstore-red: #e40613;
$color-gray-base: #141414;
$color-gray-off-white: #fafafa;
$color-gray-light: #afafaf;
$color-gray-dark: #404041;
$color-gray-01: #e1e1e1;

// Mappings
$text__color: $color-gray-base;
$accordion-control__active__background-color: $color-gray-base;
$accordion-control__color: $color-gray-base;
$accordion-control__active__color: $color-white;
$accordion-control__background-color: $color-gray-01;
$accordion-control__hover__background-color: $color-gray-01;
$accordion-control__hover__color: $color-gray-base;
$account-nav-background: transparent;
$account-nav-current-color: $color-white;
$account-nav-color: $color-white;
$breadcrumbs-current__color: $color-gray-base;
$checkbox__fill-color: $color-dstore-red;
$footer__background-color: $color-gray-base;
$footer__newsletter-background: $color-dstore-red;
$footer__newsletter-button-background: $color-gray-base;
$footer__newsletter-button-color: $color-white;
$footer__title-underline: $color-dstore-red;
$button-primary__background: $color-dstore-red;
$button-primary__hover__background: $color-gray-dark;
$button-primary__active__background: $color-gray-dark;
$price__color: $color-gray-base;
$price__special__color: $color-dstore-red;
$price__old__color: $color-gray-light;
$header-icons-color: $color-gray-base;
$link__color: $color-dstore-red;
$link__hover__color: $color-dstore-red;
$link__visited__color: $color-dstore-red;
$navigation__background: $color-gray-01;
$navigation-desktop__background: $color-gray-base;
$navigation-desktop__font-weight: $font-weight__regular;
$navigation-level0-item__color: $text__color;
$navigation-desktop-level0-item__color: $color-white;
$navigation-desktop-level0-item__active__color: $color-white;
$navigation-desktop-level0-item__hover__color: $color-white;
$submenu-item__color: $text__color;
$submenu-item__active__color: $text__color;
$input-text__background: $color-gray-off-white;
$select__background: $color-gray-off-white;
$pager-action__background: transparent;
$pager__background: $color-gray-light;
$pager__color: $color-gray-base;
$pager__hover__color: $color-gray-base;
$pager__visited__color: $color-gray-base;
$pager-current__background: $color-gray-base;
$pager-current__color: $color-white;
$tab-content__background-color: $color-gray-off-white;
$tab-control__color: $color-gray-base;
$tab-control__visited__color: $color-gray-base;
$tab-control__active__color: $color-white;
$tab-control__hover__color: $color-gray-base;
$tab-control__background-color: $color-gray-01;
$tab-control__hover__background-color: $color-gray-01;
$tab-control__active__background-color: $color-gray-base;
$checkout-shipping-item__active__border-color: $color-dstore-red;

// Misc vars
$accordion-control__line-height: 30px;
$accordion-control__font-weight: normal;
$accordion-control__font-size: 15px;
$accordion-control__margin-bottom: 2px;
$accordion-content__border: none;
$accordion-content__padding: 15px;
$navigation-level0-item__border: 2px solid $color-dstore-red;
$navigation-level0-item__padding: 12px 40px 12px 15px;
$navigation-level0-item__font-weight: normal;
$navigation-level0-item__text-transform: none;
$submenu-item__active__border: 0;
$submenu__padding-top: 7px;
$submenu__padding-bottom: 7px;
$submenu-item__active__border-width: 0;
$submenu-desktop-item__active__border-width: 0;
$pager-action__border: none;
$input-text__border: 1px solid $color-gray-base;
$select__border: 1px solid $color-gray-base;
$layout-column__left-width: 27.3%;
$layout-column-main__width-2-left: 72.7%;
$button__border-radius: 0;
$button__font-weight: $font-weight__regular;
$button-primary__border: none;
$button-primary__hover__border: none;
$button-primary__padding: 12px;
$button-primary__active__border: none;
$account-nav-current-font-weight: $font-weight__regular;
$input-text__height: 48px;
$select__height: 48px;
$form-field__vertical-indent__desktop: 17px;
$form-field-type-label-block__margin: 0 0 10px;
$breadcrumbs-current__font-weight: $font-weight__bold;
$breadcrumbs__container-margin: 10px auto;
$pager-icon__use: false;
$pager-item__padding: 0 10px;
$pager-item__margin: 0 5px 0 0;
$navigation-level0-item__active__border-width: 0;
$form-field-type-label-block__margin: 0 0 6px;
$form-field-label__font-weight: normal;
$tab-content__border: 3px solid $color-gray-base;
$tab-content__margin-top: 70px;
$tab-control__height: 70px;
$tab-control__font-weight: normal;
$tab-control__margin-right: 1px;
$tab-content__padding-top: 35px;
$tab-content__padding-right: 35px;
$tab-content__padding-bottom: 35px;
$tab-content__padding-left: 35px;
$submenu-desktop-item__padding: 2px 20px;
$checkout-sidebar__margin__xl: 0;
$navigation-desktop-level0-item__padding: 0;

// Typography
$h1__font-size-desktop: 30px;
$h1__margin-bottom__desktop: 28px;
$h1__font-weight: 400;
$h3__font-weight: $font-weight__bold;

//
//  Variables
//  _____________________________________________

$checkout-sidebar-shipping-information__padding                : $indent__l !default;
$checkout-sidebar-shipping-information__line-height            : $checkout-billing-address-details__line-height !default;

$checkout-sidebar-shipping-information-edit-icon__color        : $minicart-icons-color !default;
$checkout-sidebar-shipping-information-edit-icon__content      : $icon-settings !default;
$checkout-sidebar-shipping-information-edit-icon__font-size    : 28px !default;
$checkout-sidebar-shipping-information-edit-icon__line-height  : 28px !default;
$checkout-sidebar-shipping-information-edit-icon__top          : 2px !default;
$checkout-sidebar-shipping-information-edit-icon__hover__color : $primary__color !default;

//
//  Shipping Information
//  ---------------------------------------------

.opc-block-shipping-information {
    @include lib-css(padding, 0 $checkout-sidebar-shipping-information__padding);
    margin-top: 60px;

    .shipping-information-title {
        @include lib-font-size(20);
        border-bottom: 1px solid $color-gray-01;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-bottom: 10px;
        position: relative;

        .action-edit {
            @extend .abs-action-button-as-link;
            @include lib-font-size(12);
            top: 7px;
            margin: 0;
            position: absolute;
            right: 0;
            color: $color-gray-dark;
            text-transform: capitalize;
        }
    }

    .shipping-information-content {
        @include lib-css(line-height, $checkout-sidebar-shipping-information__line-height);
        .actions-toolbar {
            margin-left: 0;
        }
    }

    .ship-to,
    .ship-via {
        margin: 0 0 $indent__base;
    }

    //
    //  Desktop
    //
    @include min-screen($screen__m) {
        margin-top: 0;
    }
}

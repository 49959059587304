.products-carousel {
    padding: 0 15px;

    &__title {
        @include lib-font-size(22);
        line-height: 1.5;
        font-weight: normal;
        text-align: center;
        margin: 50px 0 30px;

        > strong {
            background-color: $color-gray-base;
            color: $color-white;
            padding: 0 10px;
        }

        &--smaller {
            @include lib-font-size(20);
            text-transform: uppercase;
        }
    }

    &__carousel {
        padding: 0 25px;
    }

    .slick-arrow {
        position: absolute;
        top: calc(50% - 40px);
        z-index: 1;
        color: transparent;
        background: url('../images/icons/arrow-left-dark.svg') 50% 50% no-repeat;
        background-size: 35px;
        height: 35px;
        width: 20px;
        padding: 0;
        border: none;
        cursor: pointer;

        &.slick-disabled {
            opacity: 0.2;
        }
    }

    .slick-prev {
        left: -20px;
    }

    .slick-next {
        right: -20px;
        background-image: url('../images/icons/arrow-right-dark.svg');
    }

    //
    //  Desktop
    //
    @include min-screen($screen__m) {
        &__title {
            @include lib-font-size(30);
        }
    }
}

.discount-banner {
    overflow: hidden;
    margin: 0;

    .column.main & {
        margin: 0 -20px; // page-builder extraneous padding fix
    }

    &__inner {
        padding: 13px 9999em;
        margin: 0 -9999em;
        background: $color-dstore-red;
        color: $color-white;
        text-align: center;
    }

    &__text {
        @include lib-font-size(14);
    }

    &__code-span {
        background: $color-white;
        color: $text__color;
        display: inline-block;
        padding: 2px 8px;
        margin-left: 15px;
    }

    //
    //  Desktop
    //
    @include min-screen($screen__m) {
        &__text {
            @include lib-font-size(21);
        }
    }

    //
    //  Large screens
    //
    @include min-screen($layout__max-width) {
        overflow: visible;
    }
}

.webforms {
    .form {
        .actions-toolbar,
        .fieldset,
        .field {
            padding: 0;
        }
    }

    .webforms-field {
        float: none;
    }
}

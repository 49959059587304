.homepage-banner {
    line-height: 0;

    &:not(.slick-initialized) {
        .homepage-slide:not(:first-child) {
            display: none;
        }
    }

    .homepage-slide {
        position: relative;
        display: block;
        margin: 0;
        color: $color-white;
        text-decoration: none;
        background-size: cover;
        background-repeat: no-repeat;

        &:hover,
        &:visited {
            color: $color-white;
            text-decoration: none;
        }

        &__images {
            width: 100%;
        }

        &__image {
            min-height: 400px;
            object-fit: cover;
        }

        &__desktop,
        &__mobile {
            width: 100%;
            margin: 0;
        }

        &__desktop {
            display: none;

            @include media-breakpoint-up(md) {
                display: block;
            }
        }

        &__mobile {
            display: block;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }

    .slick-slide {
        > .homepage-slide {
            display: block;
        }
    }

    &.slick-initialized {
        .homepage-slide {
            display: block;
        }
    }

    .slick-dots {
        position: absolute;
        width: 100%;
        bottom: 5px;
        right: 0;
        list-style: none;
        margin: 0;
        padding: 15px;
        text-align: center;

        li {
            display: inline-block;
            margin: 0 15px 0 0;

            &:last-child {
                margin-right: 0;
            }

            button {
                height: 15px;
                width: 15px;
                padding: 0;
                border-radius: 100%;
                font-size: 0;
                background: transparent;
                border: 1px solid $color-gray-base;

                &:hover {
                    background: $color-gray-base;
                }

                &::before {
                    display: none;
                }
            }

            &.slick-active {
                button {
                    background: $color-gray-base;

                    &:hover {
                        background: $color-gray-base;
                    }
                }
            }
        }
    }
}

.main-menu {
    //
    //  Desktop
    //  _____________________________________________
    @include min-screen($screen__m) {
        display: block;
        padding: 0 20px;

        &__list {
            margin: 0;
            padding: 0;
            position: relative;
        }

        &__item {
            margin: 0;
            float: left;
            width: calc(100% / 7);
            text-align: center;

            &:last-child {
                background: $color-dstore-red;

                .main-menu__link {
                    &:hover {
                        @include link-color-all($color-white);
                        background: $color-dstore-red;
                    }
                }
            }
        }

        &__link {
            @include link-color-all($color-white);
            height: 50px;
            display: table;
            width: 100%;
            position: relative;
            padding: 0 5px;

            &:hover {
                text-decoration: none;
            }

            .main-menu__item:hover & {
                @include link-color-all($text__color);
                background: $color-white;
            }

            &:before {
                content: '';
                position: absolute;
                top: 10px;
                right: -1px;
                height: calc(100% - 20px);
                width: 1px;
                background: $color-dstore-red;

                .main-menu__item:last-child & {
                    display: none;
                }
            }

            &:hover:before {
                display: none;
            }
        }

        &__link-inner {
            display: table-cell;
            vertical-align: middle;
        }

        &__inner-list {
            padding: 0;

            &--level1 {
                display: none;
                position: absolute;
                top: 50px;
                left: 0;
                width: 100%;
                background-color: $color-white;
                z-index: 1000;
                padding-right: 20px;
                border: none;
                border-top: 1px solid #e40613;
                margin-top: -1px;
                box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);

                .main-menu__item:hover & {
                    display: block;
                }
            }
        }

        &__inner-item {
            text-align: left;
            margin: 0;

            &--level1 {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 0;
                padding: 27px 5px 10px;

                > .main-menu__inner-link {
                    @include link-color-all($color-dstore-red);
                    font-weight: 700;
                }
            }

            &--all {
                display: none;
            }
        }

        &__inner-link {
            @include link-color-all($text__color);
            display: block;
            padding: 2px 20px;
        }

        .menu-children {
            flex: 0 0 100%;
            width: 100%;

            > ol {
                display: flex;
                flex-wrap: wrap;
                padding: 0;
                width: 20%;
                float: left;

                &.menu-cms-block {
                    display: block;
                    padding: 35px 0;
                    width: 60%;

                    .action {
                        width: 100%;
                        margin-top: -5px;
                    }
                }
            }
        }
    }

    //
    //  Mobile
    //  _____________________________________________
    @include max-screen($screen__m) {
        font-size: 16px;
        padding: 0;

        &__list {
            margin: 0;
            padding: 0;
        }

        &__item {
            margin: 0;

            &:not(:last-child) {
                border-bottom: 2px solid $color-dstore-red;
            }
        }

        &__link,
        &__inner-link {
            @include link-color-all($text__color);
            display: block;

            &:hover {
                text-decoration: none;
            }

            &.is-submenu {
                background: url('../images/icons/arrow-expand-dark.svg') calc(100% - 15px) 50% / 16px no-repeat transparent;
            }

            &.is-open.is-submenu {
                background-image: url('../images/icons/arrow-collapse-dark.svg');
            }
        }

        &__link {
            padding: 12px 40px 12px 15px;
        }

        &__inner-link {
            padding: 7px 0;
        }

        &__inner-list {
            display: none;
            background: $color-white;
            margin: 0;
            padding-left: 15px;

            &--level1 {
                border-top: 2px solid $color-dstore-red;
            }
        }

        &__inner-item {
            margin: 0;
        }

        .is-open {
            + ul {
                display: block;
            }
        }

        .menu-children {
            margin: 0;
            padding: 0;

            > ol {
                padding: 0;
            }

            .menu-cms-block {
                display: none;
            }
        }
    }
}

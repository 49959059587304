.amcard-giftcards,
.amcard-field-container {
    .actions-toolbar {
        margin-left: 10px;

        .action {
            padding: 10px 12px;
        }
    }

    .amcard-title {
        display: block;
        margin: 10px 0;

        .checkout-cart-index & {
            display: none;
        }
    }

    .amcard-field-block {
        display: flex;
    }

    .amcard-field {
        height: 40px;
    }

    .amcard-status-link {
        color: $color-white;
        float: left;
        margin: 5px 0 15px;
    }
}

.minicart-wrapper {
    padding-left: 30px;
    margin-top: 17px;
    position: relative;
    height: 24px;
    display: block;

    &:before {
        content: '';
        background: url('../images/icons/basket-dark.svg') 0 -3px no-repeat transparent;
        background-size: contain;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 30px;
        width: 30px;
    }

    .action.showcart {
        padding: 0;

        &:before,
        &.active:before {
            display: none;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .counter.qty {
            @include lib-font-size(20);
            background: transparent;
            color: $color-dstore-red;
            font-weight: bold;
            margin: 1px 0 0 4px;
        }

        .counter-suffix {
            display: none;
        }
    }

    //
    // Desktop
    //
    @include min-screen($screen__m) {
        margin-top: 29px;
        margin-left: 5px;

        .action.showcart {
            .counter-suffix {
                @include lib-font-size(14);
                display: inherit;
                text-transform: uppercase;
                color: $text__color;
                position: relative;
                top: -2px;
            }
        }
    }
}

.minicart-items {
    .action {
        &.delete,
        &.edit {
            &:before {
                display: none;
            }
        }

        &.delete {
            display: block;
            width: 22px;
            height: 20px;
            margin-top: -12px;
            background: url('../images/icons/bin-red.svg') 0 0 no-repeat transparent;
            background-size: 22px;

            > span {
                display: none;
            }
        }
    }
}

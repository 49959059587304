.amquickview-hover {
    position: absolute;
    left: 0;
    bottom: 10px;
    z-index: 1;
    width: 100%;
    text-align: center;
}

.amquickview-link {
    @extend .action.primary;
    position: relative;
    display: inline-block;
    padding: 6px 12px 6px 25px;
}

.am-quickview-icon {
    position: absolute;
    top: 8px;
    left: 6px;
}

//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin              : $indent__base !default;
$checkout-wrapper__columns             : 8 !default;

$checkout-step-title__border           : $border-width__base solid $color-gray80 !default;
$checkout-step-title__font-size        : 26px !default;
$checkout-step-title__font-weight      : $font-weight__light !default;
$checkout-step-title__padding          : $indent__s !default;

$checkout-step-title-mobile__font-size : 18px !default;

.checkout-index-index {
    .page-header {
        background: #f5f5f5;
        padding: 20px 0 30px;
    }

    .page-title-wrapper {
        @extend .abs-visually-hidden;
    }

    .header.content {
        padding: 0;
    }

    .minicart-wrapper .action.showcart {
        position: absolute;
        top: -15px;
        right: -15px;
        border: none;
        background: $color-dstore-red;
        height: 25px;
        width: 25px;
        padding: 0;
        line-height: 0;
        border-radius: 100%;

        .counter.qty {
            @include lib-font-size(14);
            color: $color-white;
            margin: 0;
        }

        &:after {
            height: 40px;
            width: 45px;
            left: auto;
            right: 0;
        }
    }

    .logo {
        margin-left: 15px;
    }

    input[type="text"],
    input[type="password"],
    input[type="url"],
    input[type="tel"],
    input[type="search"],
    input[type="number"],
    input[type="datetime"],
    input[type="email"],
    select {
        background: transparent;
        border: 1px solid $color-gray-01;
    }

    .fieldset > .field,
    .fieldset > .fields > .field {
        text-transform: none;

        > .label {
            font-weight: bold;
        }
    }

    //
    //  Desktop
    //
    @include min-screen($screen__m) {
        .page-header {
            padding: 15px 0;
            margin-bottom: 30px;
        }

        .header.content {
            background: url('../images/trustpilot.png') 15px 50% no-repeat transparent;
        }

        .logo {
            width: 325px;
            display: block;
            float: none;
            margin: 0 auto;
        }
    }
}

#opc-sidebar {
    border: 1px solid $color-gray-01;
}

.field.street .field .label {
    @include lib-visually-hidden();
}

.checkout-secure-icon {
    float: right;
    margin-top: 10px;
    margin-right: 15px;
    background: url('../images/icons/padlock-dark.svg') 0 50% no-repeat transparent;
    background-size: 25px;
    padding: 5px 0 2px 32px;
    text-align: right;

    &__text {
        @include lib-font-size(18);
        color: $color-gray-dark;
    }

    //
    //  Desktop
    //
    @include min-screen($screen__m) {
        position: absolute;
        margin-top: 0;
        top: 25px;
        right: 15px;
        max-width: 32%;
        background-size: 30px;
    }
}

.checkout-container {
    @extend .abs-add-clearfix;
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);
}

.opc-wrapper {
    @include lib-css(margin, 0 0 $checkout-wrapper__margin);

    .opc {
        @extend .abs-reset-list;
        border: 1px solid $color-gray-01;
        padding: 20px 15px;
    }

    .step-title {
        @include lib-font-size(20);
        font-weight: bold;
    }

    .step-content {
        margin: 0 0 $indent__xl;
    }
}

.checkout-index-index {
    .nav-sections,
    .nav-toggle {
        display: none;
    }
}

.checkout-onepage-success {
    @extend .abs-add-clearfix;

    .print {
        display: none;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .opc-wrapper {
        .step-title {
            @include lib-css(font-size, $checkout-step-title-mobile__font-size);
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .opc-wrapper {
        @extend .abs-add-box-sizing-desktop-m;
        @include lib-layout-column(2, 1, $checkout-wrapper__columns);
        padding-right: $indent__l;

        .opc {
            max-width: 650px;
        }
    }

    .checkout-onepage-success {
        .print {
            display: block;
            float: right;
            margin: 22px 0 0;
        }
    }
}

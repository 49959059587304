.wam-product-gallery {
    &__main-gallery {
        margin: 0 0 15px;
    }

    &__thumbs {
        position: relative;

        &:not(.is-no-slider) {
            margin: 0 30px;
        }

        .slick-prev,
        .slick-next {
            position: absolute;
            top: calc(50% - 20px);
            height: 40px;
            width: 23px;
            background: url('../images/icons/arrow-left-dark.svg') 50% 50% no-repeat transparent;
            font-size: 0;
            background-size: 30px;

            &.slick-disabled {
                opacity: 0.2;
            }
        }

        .slick-prev {
            left: -30px;
        }

        .slick-next {
            right: -30px;
            background-image: url('../images/icons/arrow-right-dark.svg');
        }
    }

    &__thumb {
        padding: 0 2px;
        display: inline-block;

        .is-no-slider & {
            padding: 0 5px;
            max-width: calc(25% - 3px);

            &:last-child {
                padding-right: 0;
            }

            &:first-child {
                padding-left: 0;
            }
        }
    }

    &__thumb-link {
        position: relative;
        box-sizing: border-box;
        display: block;
        border: 2px solid transparent;
        width: 135px;
        max-width: 100%;
        padding-bottom: 100%;

        &.is-current {
            border-color: $color-dstore-red;
        }
    }

    &__thumb-image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    //
    //  Mobile
    //
    @include max-screen($screen__m) {
        &__main-gallery {
            margin: 0 0 10px;
        }

        &__thumbs {
            margin-bottom: 10px;

            &:not(.is-no-slider) {
                margin: 0 20px 10px;
            }

            .slick-prev,
            .slick-next {
                background-size: 20px;
            }
        }
    }
}

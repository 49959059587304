.scope {
    &--footer-newsletter {
        > p,
        > h3 {
            text-transform: uppercase;
        }

        > h3 {
            @include lib-font-size(16);
            font-weight: bold;
            margin: 0;
        }
    }

    &--contact-top {
        h2 {
            @include lib-font-size(14);
            text-transform: none;
            font-weight: bold;
            margin: 30px 0 0;
        }

        .phone {
            @include lib-font-size(20);
            color: $text__color;
            display: block;
            margin: 10px 0;

            &:hover,
            &:visited {
                color: $text__color;
            }

            &:before {
                content: '';
                display: inline-block;
                height: 30px;
                width: 22px;
                background: url('../images/icons/contact-phone.svg') 50% 50% no-repeat transparent;
                background-size: 30px;
                margin: 0 5px -7px 5px;
            }
        }
    }
}

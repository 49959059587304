//
//  Variables
//  _____________________________________________

$filter-options-item__border_bottom_color: $color-gray80;
$filter_block_clear_button__background_color: $color-gray95;
$filter_block_clear_button__border-color: $color-gray82;
$filter_block_clear_button__color: $color-gray52;
$filter_block_clear_button__hover__border-color: $color-gray60;
$filter_block_clear_button__hover__color: $color-gray20;
$popover__background-color: #fafafa;
$popover__border-color: $color-blue1;
$slider_handle__color: $color-gray-light5;
$slider_handle_border__color: $color-gray60;
$slider_handle__hover__color: $color-gray64;
$slider_range__color: $color-blue1;
$show-more__color: $color-blue1;
.pages {
    @include lib-pager();
    margin-top: 10px;

    .action {
        width: 34px;

        &.previous,
        &.next {
            background-position: 50% 50%;
            background-size: 16px;
            background-repeat: no-repeat;

            > span {
                color: transparent;
            }
        }

        &.previous {
            background-image: url('../images/icons/arrow-left-dark.svg');
        }

        &.next {
            background-image: url('../images/icons/arrow-right-dark.svg');
        }
    }

    .items {
        position: relative;

        .pages-item-previous,
        .pages-item-next {
            position: absolute;
            top: 0;
        }

        .pages-item-previous {
            left: -40px;
        }

        .pages-item-next {
            right: -40px;
        }
    }
}

.faq-widget {
    &__title {
        @include lib-font-size(20);
        margin: 0 0 20px;
    }

    &__accordion-title {
        padding: 10px;
        margin-bottom: 15px;
        background: $color-gray-light;
    }

    &__accordion-content {
        margin-bottom: 20px;
    }
}

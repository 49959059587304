.product {
    &.data {
        &.items {
            > .item.title {
                text-align: center;

                > .switch {
                    border: none;
                    text-transform: uppercase;
                }

                &.active > .switch {
                    color: $color-white;
                }
            }

            > .item.content {
                ul {
                    padding-left: 20px;
                    list-style: none;

                    > li {
                        position: relative;

                        &:before {
                            content: "•";
                            color: $color-dstore-red;
                            position: absolute;
                            top: 0;
                            left: -15px;
                        }
                    }
                }
            }
        }
    }
}

//
//  Desktop
//
@include min-screen($screen__m) {
    $_tabs-title-height: 50px;

    .product {
        &.data {
            &.items {
                > .item.title {
                    width: calc(20% - 1px);
                    display: table;

                    > .switch {
                        display: table-cell;
                        vertical-align: middle;
                        height: $_tabs-title-height;
                    }
                }

                > .item.content {
                    margin-top: $_tabs-title-height;
                }
            }
        }
    }
}

@import 'module/cart';
@import 'module/minicart';
@import 'module/minicart_extend';
@import 'module/checkout';

$_stack-summary-below-width: 980px;

.checkout-summary {
    text-align: left;

    &__top {
        margin-bottom: 30px;
    }

    &__box,
    &__items {
        border: 1px solid $color-gray-light;
        padding: 30px;
        margin-bottom: 30px;
    }

    &__box-title {
        display: block;
        font-weight: normal;
        margin-bottom: 15px;

        &:before {
            content: '';
            display: block;
            height: 30px;
            width: 30px;
            background: url('../images/icons/location-pin-red.svg') 50% 50% no-repeat transparent;
            background-size: contain;
            margin-bottom: 15px;
        }
    }

    &__box {
        &--shipping-address {
            .checkout-summary__box-title:before {
                background-image: url('../images/icons/location-pin-red.svg');
            }
        }

        &--billing-address {
            .checkout-summary__box-title:before {
                background-image: url('../images/icons/card-red.svg');
            }
        }

        &--shipping-method {
            .checkout-summary__box-title:before {
                background-image: url('../images/icons/delivery-truck-red.svg');
            }
        }
    }

    &__items {
        flex-grow: 1;

        .box-title {
            display: block;
            margin-bottom: 20px;
        }

        thead {
            display: none;
        }

        .cart.table-wrapper {
            border: 1px solid $color-gray-light;
        }

        .items-qty {
            list-style: none;
            margin: 0;
            padding: 0;
            display: inline;

            .item {
                display: inline;

                .title {
                    display: none;
                }
            }
        }
    }

    .cart {
        &.table-wrapper {
            .col {
                &.qty,
                &.subtotal {
                    padding-left: 10px;
                }

                &.qty {
                    padding-top: 15px;
                }
            }

            .product-item-photo {
                position: static;
                margin-bottom: 10px;
            }
        }
    }

    &__bottom {
        display: flex;
        flex-direction: column;
    }

    //
    // Desktop
    //
    @include min-screen($screen__m) {
        &__top {
            display: table;
            width: 100%;
        }

        &__box {
            display: table-cell;
            width: 33.33%;

            &--shipping-address,
            &--billing-address {
                border-right: none;
            }

            &--billing-address,
            &--shipping-method {
                margin-left: -1px;
            }
        }

        &__items {
            .items-qty {
                .item {
                    .title {
                        display: inline;
                    }
                }
            }
        }
    }

    @include min-screen($_stack-summary-below-width) {
        &__bottom {
            flex-direction: row;
        }
    }
}

.checkout-onepage-success {
    .page-title-wrapper {
        text-align: center;
        position: relative;

        .action.print {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .page-title {
        font-weight: bold;
        color: $color-dstore-red;
    }

    .checkout-success {
        text-align: center;

        &__order-number {
            @include lib-font-size(21);
        }

        &__message {
            max-width: 900px;
            margin: 0 auto 50px;
        }
    }
}

.lastpush {
    margin-bottom: 30px;

    &__action {
        width: 100%;
        text-align: center;
    }

    //
    // Desktop
    //
    @include min-screen($_stack-summary-below-width) {
        min-width: 400px;
        margin-right: 20px;
    }
}

.icon-text-object {
    @include lib-clearfix();
    clear: both;
    padding-top: 5px;

    &--last {
        margin-bottom: 20px;
    }

    &__icon {
        float: left;
        height: 35px;
        width: 35px;
        margin: -5px 15px 10px 0;
    }

    &__text {
        @include lib-font-size(18);

        > a {
            color: $text__color;

            &:hover,
            &:visited {
                color: $text__color;
            }
        }
    }
}

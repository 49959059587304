.header-usps {
    background: $color-gray-light;
    padding: 10px 0;

    &__slider {
        > .js-cms-content {
            display: none;
        }
    }

    &__link,
    &__link:visited,
    &__link {
        color: $color-white;
    }

    &__item {
        float: left;
        width: (100% / 5);
        padding-right: 5px;
        padding-left: 55px;
        background-position: 0 -7px;
        background-repeat: no-repeat;
        background-size: 45px;

        &[data-items="4"] {
            width: (100% / 4);
        }

        &--dstore {
            background-image: url('../images/icons/cvp/largest-dstore.svg');
        }

        &--delivery {
            background-image: url('../images/icons/cvp/delivery.svg');
        }

        &--returns {
            background-image: url('../images/icons/cvp/returns.svg');
        }

        &--price {
            background-image: url('../images/icons/cvp/price-match-policy.svg');
        }

        &--review {
            background-image: url('../images/icons/cvp/trustpilot.svg');
        }
    }

    &__title {
        margin: 0;
        @include lib-font-size(12);
    }

    &__subtitle {
        @include lib-font-size(10);
        text-transform: uppercase;
    }

    @include max-screen(646px) {
        padding-left: 30%;
    }

    @include max-screen(450px) {
        padding-left: 15%;
    }

    //
    // Desktop
    //
    @include min-screen($screen__m) {
        &__item:not(:first-child) {
            display: block;
        }
    }
}

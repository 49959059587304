.block-search {
    position: relative;

    .label {
        @include lib-visually-hidden();

        &:before {
            display: none;
        }
    }

    .control {
        border-top: none;
    }

    input {
        position: static;
        padding-right: 30px;
        height: 32px;
    }

    .action.search {
        display: block;
        position: absolute;
        bottom: 15px;
        right: 0;
        background: url('../images/icons/search.svg') center center no-repeat transparent;
        background-size: 20px;
        height: 32px;
        width: 32px;
        border: none;

        > span {
            @include lib-visually-hidden();
        }

        &:before {
            display: none;
        }
    }

    //
    // Desktop
    //
    @include min-screen($screen__m) {
        width: 320px;
        max-width: 40%;
        margin-top: 25px;
    }
}

.search-autocomplete {
    ul {
        li:not(:first-child) {
            margin-top: -1px;
        }
    }
}

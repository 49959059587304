.input-wrap {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px -4px 0;
    background: $input-text__background;
    border: $input-text__border;

    > input {
        display: none;
    }

    > input:checked + .input-pseudo:after {
        content: '';
        position: absolute;
        top: 1px;
        left: 1px;
        height: calc(100% - 2px);
        width: calc(100% - 2px);
        background: $checkbox__fill-color;
    }

    &--radio {
        border-radius: 20px;

        > input:checked + .input-pseudo:after {
            border-radius: 20px;
        }
    }
}
